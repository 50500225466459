/* src/components/PricingTable.css */
.pricing-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Clash", sans-serif;
    max-width: 1440px;
    margin: 2rem auto 4rem;
    padding: 1rem;
    height: 100%;
    min-height: 100vh;
  }
.pricing_title::after {
  content: "";
  position: absolute;
  top: 25%;
  left: 100%;
  transform: translateY(-50%);
  background: url(../images/circle.png) no-repeat center center;
  width: 300px;  
  height: 300px;
  background-size: contain;
}
@media screen and (max-width: 1400px) {
  .pricing_title::after {
    top: -4rem;
    left: initial;
    right: -3rem;
    transform: none;
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 767px) {
  .pricing_title::after {
    display: none;
  }
}


  .poular_tag {
    position: absolute;
    bottom: 98.5%;
    right: 2rem;
    background-color: black;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: bold;
    animation: grow 5s infinite;
  }
  .poular_tag p {
    text-transform: uppercase;
    font-size: 14px;
    color: white;
  }
  @keyframes grow {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
  }
  
  .pricing-table h1 {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 990px) {
    .pricing-table h1 {
      margin-bottom: 0;
    }
  }
  
  .pricing-plans {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
  @media screen and (max-width: 990px) {
    .pricing-plans {
      display: block;
    }
  }
  .plan_left {
    grid-area: 1 / 1 / 2 / 2;
    border: 1px solid black;
  }
  .plan_middle {
    grid-area: 1 / 2 / 2 / 3;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  @media screen and (max-width: 990px) {
    .plan_middle {
      border-top: none;
      border-bottom: none;
      border-left: 1px solid black;
      border-right: 1px solid black;
    }
  }
  .plan_right {
    grid-area: 1 / 3 / 2 / 4;
    border: 1px solid black;
  }

  .plan {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    position: relative;
    background-color: white;
  }
  
  .plan.popular {
    border: 2px solid #000;
    background-color: #f0f0f0;
  }
  
  .plan h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .price {
    font-size: 24px;
    color: #000;
  }
  
  .engagement {
    font-size: 14px;
    color: #777;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  ul li {
    margin: 10px 0;
  }

  .tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    top: -5px;
    left: 105%;
    white-space: nowrap;
    z-index: 10;
  }
  
  .includes {
    padding: 1rem;
    text-align: left;
  }
  
  .includes p {
    margin: 5px 0;
  }
  
  .btn {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  