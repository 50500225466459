@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Panchang';
  src: url('./fonts/Panchang-Variable.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Clash-500';
  src: url('./fonts/ClashDisplay-Medium.otf') format('OpenType');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Clash-700';
  src: url('./fonts/ClashDisplay-Semibold.otf') format('OpenType');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Clash-400';
  src: url('./fonts/ClashDisplay-Regular.otf') format('OpenType');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'HandstaSignature';
  src: url('./fonts/HandstaSignature.otf') format('OpenType');
  font-weight: 400;
  font-style: normal;
}