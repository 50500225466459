.button {
    padding: .75rem 6rem;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}
@media screen and (max-width: 1100px) {
    .button {
        padding: .75rem 3rem;
    }
}
.button-white {
    background-color: white;
    color: #4D2DE4;
    border: 2px solid #4D2DE4;
}
.button-white:hover {
    background-color: black;
    color: white;
    border: 2px solid black;
}

.button-blue {
    background-color: #4D2DE4;
    color: white;
    border: 2px solid #4D2DE4;
}
.button-blue:hover {
    background-color: black;
    border: 2px solid black;
}

.button-grey {
    background-color: #D5D7D9;
    color: #818384;
    cursor: not-allowed;
}