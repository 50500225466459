.info-icon  {
    width: 13px!important;
    height: 13px!important;
    max-width: none;
    z-index: 10;
}
.info-icon  img {
    width: 13px!important;
    height: 13px!important;
    max-width: none;
}
.class-invalid {
    text-decoration: line-through;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9; /* Assurez-vous que cela est en dessous de votre z-index pour la div d'info */
}

.info-tooltip {
    position: absolute;
    background-color: #4a00e0; /* Replace with your bg color */
    color: white;
    text-align: center;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    width: 360px;
    bottom: 100%; 
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    z-index: 10;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.info-tooltip::after {
    content: '';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: #4a00e0 transparent transparent transparent; /* Match bg color */
}

@media screen and (max-width: 1600px) {
    .info-tooltip {
        width: 275px;
        left: -250px;
        transform: none;
    }
    .info-tooltip::after {
        left: 90%;
        transform: translateX(-10%);
        border-width: 10px;
        border-style: solid;
        border-color: #4a00e0 transparent transparent transparent; /* Match bg color */
    }
}